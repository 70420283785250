export const content = [
  {
    id: "content-1",
    title: "Rigorous screening",
    description:
      "We thoroughly vet each of the therapists and prescribers who join us.",
  },
  {
    id: "content-2",
    title: "Ongoing education",
    description:
      "We provide continuous training so our clinicians are always improving their skills in treating patients.",
  },
  {
    id: "content-3",
    title: "Monthly evaluations",
    description:
      "Cerebral clinicians undergo evaluations each month to ensure they maintain our high standards.",
  },
  {
    id: "content-4",
    title: "Diverse clinician base",
    description:
      "50% of our clinicians come from minority backgrounds, increasing options for patients so you can find an expert that best meets your needs.",
  },
  {
    id: "content-5",
    title: "LGBTQIA+ experience",
    description:
      "Cerebral clinicians are experienced in providing supportive and ethical care to members of the LGBTQIA+ community.",
  },
]
