import { H2 } from "../../../ui"
import {
  ExpertContainer,
  Subheading,
  ExpertSection,
  ExpertInfo,
  ExpertRole,
  ExpertBio,
  Heading,
  ExpertName,
} from "./styled"
import { HealthcareExpertType } from "../../types"
import { GeneratedGatsbyImage } from "../../../utils/utils"
export const HealthcareExperts = ({
  healthcareExperts,
}: {
  healthcareExperts: HealthcareExpertType[]
}) => {
  return (
    <ExpertContainer>
      <Heading>
        <H2>Renowned Healthcare Experts at the Helm</H2>
      </Heading>
      <Subheading>
        Our leaders are highly-accomplished and revered in the field of mental
        health medicine.
      </Subheading>
      {healthcareExperts.map(expert => (
        <ExpertSection key={expert.name.text}>
          <GeneratedGatsbyImage
            imageData={expert.headshot}
            alt={expert.name.text}
          />
          <ExpertInfo>
            <ExpertName>{expert.name.text}</ExpertName>
            <ExpertRole>{expert.role.text}</ExpertRole>
            <ExpertBio>{expert.bio}</ExpertBio>
          </ExpertInfo>
        </ExpertSection>
      ))}
    </ExpertContainer>
  )
}
