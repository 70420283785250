import convenientsvg from "./assets/convenient.svg"
import personalizedsvg from "./assets/personalized.svg"
import trustedsvg from "./assets/trusted.svg"

export const contents = [
  {
    id: "apt-content-1",
    title: "Trusted",
    desc: [],
    bullets: [
      "We’re selective—only partnering with top therapists and medical prescribers who are experienced in getting results, ensuring you receive first-class care.",
      "Patients rate our mental health experts 4.8/5.0 and we only continue working with those who maintain our exceptional standards.",
      "Cerebral and all our health care professionals are subject to HIPAA compliance and take your privacy seriously, so you can feel comfortable knowing your personal information will remain secure and confidential.",
    ],
    images: [
      {
        srcSet: trustedsvg,
        height: "300",
        width: "300",
        name: "apt-fit-info-desktop",
        media: "(min-width:835px)",
      },
      {
        srcSet: trustedsvg,
        height: "200",
        width: "200",
        name: "apt-fit-info-mobile",
        media: "(max-width:834px)",
      },
    ],
  },
  {
    id: "apt-content-2",
    title: "Personalized",
    desc: [],
    bullets: [
      "We help you choose a mental health expert with experience that matches your needs.",
      "We consider your complete identity, medical history, and symptoms when presenting you with a short list of experts from our diverse clinician base.",
      "Your care professional will be there for you long-term—so you can trust them.",
    ],
    images: [
      {
        srcSet: personalizedsvg,
        height: "300",
        width: "300",
        name: "apt-growing-info-desktop",
        media: "(min-width:835px)",
      },
      {
        srcSet: personalizedsvg,
        height: "200",
        width: "200",
        name: "apt-growing-info-mobile",
        media: "(max-width:834px)",
      },
    ],
  },
  {
    id: "apt-content-3",
    title: "Convenient & Flexible",
    desc: [],
    bullets: [
      "Life can be hectic, so we make it simple to connect with your mental health care provider right from your home.",
      "Schedule virtual sessions when it’s convenient for you with availability days, nights, and weekends.",
      "When using our in-house pharmacy CerebralRx, patients receive complimentary 2-day shipping on medications.",
    ],
    images: [
      {
        srcSet: convenientsvg,
        height: "300",
        width: "300",
        name: "apt-growing-info-desktop",
        media: "(min-width:835px)",
      },
      {
        srcSet: convenientsvg,
        height: "200",
        width: "200",
        name: "apt-growing-info-mobile",
        media: "(max-width:834px)",
      },
    ],
  },
]
