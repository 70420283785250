import React from "react"

import { Wrapper, QuoteWrapper, TextWrapper } from "./styled"
import quotationImage from "../../../shared/assets/quotes-primary.svg"

export const Quotation = (): JSX.Element => {
  return (
    <Wrapper>
      <img src={quotationImage} alt="" loading="lazy" width={63} height={45} />
      <QuoteWrapper>
        Your mental health is too important to ignore. Our experienced
        clinicians help you easily prioritize your mental health so you enjoy
        better days, happier years, and a fuller life.
      </QuoteWrapper>
      <TextWrapper>Cerebral CEO David Mou, MD</TextWrapper>
    </Wrapper>
  )
}
